<template>
  <v-container>
    <v-card elevation="0">
      <v-col md="11">
      <v-row>
        <v-col md="4">
          <v-autocomplete 
          v-model="data.id"
            :label="$t('Full Name')"
            clearable :items="childrens" :loading="childrensLoading" color="#757575" item-text="name"
            item-value="id"  @keypress="fetchRegRecordAutoComplete($event)"
            >
          </v-autocomplete>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="data.name"
            :label="$t('student name')"
            clearable
          ></v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="data.last_name"
            :label="$t('last name')"
            clearable
          ></v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="data.father_name"
            :label="$t('father name')"
            clearable
          ></v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="data.mother_name"
            :label="$t('mother name')"
            clearable
          ></v-text-field>
        </v-col>
        
        </v-row>
      </v-col>

        <v-row>
          <v-col md="2">
            <v-autocomplete
              color="#757575"
              :loading="data.eduClassesLoading"
              item-text="name"
              item-value="id"
              :label="$t('educitional class')"
              @input="fetchEduClasses"
              v-model="data.edu_class"
              :items="data.eduClasses"
              no-filter
            ></v-autocomplete>
          </v-col>  
          <v-col md="2">
           <v-autocomplete
                color="#757575"
                item-text="name"
                item-value="id"
                :loading="data.gradesLoading"
                :label="$t('class')"
                v-model="data.grade"
                :items="data.grades"
                @change="fetchEduClasses"
                no-filter
            ></v-autocomplete>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="data.public_record_number"
            :label="$t('public record number')"
            clearable
          ></v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="data.phone_number"
            :label="$t('phone number')"
            clearable
          ></v-text-field>
        </v-col>
        <v-col md="1">
          <v-select
            v-model="data.type"
            :items="typeItems"
            :label="$t('type')"
            item-text="name"
            item-value="value"
          ></v-select>
        </v-col>
        <v-col md="1">
          <v-select
            v-model="data.gender"
            :items="genderItems"
            :label="$t('gender')"
            item-text="name"
            item-value="value"
          ></v-select>
        </v-col>
        <v-col md="1" style="display: flex; justify-content: center">
          
            <v-icon large color="gray" @click="data = {}">mdi-close</v-icon>
          
        </v-col>
          <v-col md="1" class="py-7">
            <v-icon large color="gray" @click="setData"> mdi-magnify </v-icon>
          
          </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { meal } from "../../../../store/auth";
import axios from "axios";
import debounce from "../../../../helpers/debounce";

export default {
  data() {
    return {
      meal,
      childrens: null,
      childrensLoading: false,
      data: {
        id: null,
        name: null,
        type: null,
        gender: null,
        last_name: null,
        father_name: null,
        mother_name: null,
        public_record_number: null,
        phone_number: null,
        gradesLoading: false,
        eduClassesLoading: false,
        grade: null,
        edu_class: null,
        grades: [],
        eduClasses: [],
      },
    };
  },
  methods: {
    setData() {
      this.$emit("setDataFilter", this.data);
    },
    async fetchEduClasses() {
      try {
        this.eduClassesLoading = true;
        const eduClasses = await axios.get("/edu-class",{
          params: {
            paginated: false,
          }
        });
        this.data.eduClasses = eduClasses.data.classes;
      } catch (err) {
      } finally {
        this.eduClassesLoading = false;
      }
    },
    async fetchRegRecordAutoComplete(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.childrensLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/student/auto-complete", {
            params: {
              name: searchValue,
              type: "full_name",
            },
          });
          this.childrens = searchResult.data.data;
          
        } catch (err) {
          console.log("err", err);
        } finally {
          this.childrensLoading = false;
        }
      }, 1000)();
    },
  },
  computed: {
    typeItems() {
      return [
        {
          name: this.$t("new"),
          value: "new",
        },
        {
          name: this.$t("Transported"),
          value: "transported",
        },
      ];
    },
    genderItems() {
      return [
        {
          name: this.$t("male"),
          value: "male",
        },
        {
          name: this.$t("female"),
          value: "female",
        },
      ];
    },
  },
  async created() {
    try {
      this.data.gradesLoading = true;
      const response = await axios.get("/grade", {
        params: {
          size: 100000,
        },
      });
      this.data.grades = response.data.grades;
      this.fetchEduClasses();
    } catch (err) {
      console.log("err", err);
    } finally {
      this.data.gradesLoading = false;
    }
  },
};
</script>
